import { defineStore } from 'pinia'
import { useLocalStorage } from '@vueuse/core'
import { useWorkerStore } from '@/stores'

import liffapi from '@/liffapi'

function asSummaryClaim(claim) {
  const ret = {
    key: claim.key,
    itemUrl: claim.itemUrl,
    itemRef: claim.itemRef,
    category: claim.category,
    personName: claim.user.name,
    state: claim.state,
    work_category: claim.work_category,
    isMessaged: claim.isMessaged,
    dateCreated: claim.dateCreated,
  }
  return ret
}

export const useOrgStore = defineStore('organization', {
  state: () => ({
    categories: useLocalStorage('categories', {}),
    claimsByKey: useLocalStorage('claims', {}),
    inbox: useLocalStorage('inbox', []),
    notifications: [],
    org: useLocalStorage('organization', {}),
    parcelTemplates: {},
    recentReturns: useLocalStorage('recentReturns', []),
    shipments: useLocalStorage('shipments', {}),
    summaryClaimsByKey: useLocalStorage('summaryClaims', {}),
  }),

  getters: {
    analyticsUrl(state) {
      return state.org?.analyticsUrl
    },

    categoryData(state) {
      return state.categories
    },

    claims(state) {
      return Object.values(state.claimsByKey)
    },

    hasAds(state) {
      return state.org?.displayOptions?.hasAds
    },

    isLoaded(state) {
      return Boolean(state.org?.key)
    },

    key(state) {
      return state.org?.key
    },

    mapUrl(state) {
      return state.org?.mapUrl
    },

    name(state) {
      return state.org?.name
    },

    pin(state) {
      return state.org?.itemOptions?.pin
    },

    protectWallpaperUrl(state) {
      return (
        state.org?.protectOpts?.wallpaperUrl ||
        state.org?.displayOptions?.protectWallpaperUrl
      )
    },

    protectOpts(state) {
      return state.org?.protectOpts
    },

    summaryClaims(state) {
      return Object.values(state.summaryClaimsByKey)
    },

    slug(state) {
      return state.org?.slug
    },
  },

  actions: {
    addNotification(note) {},

    addReturnItem(item) {
      this.recentReturns.unshift(item)
      while (this.recentReturns.length > 5) {
        this.recentReturns.shift()
      }
    },

    fetchClaim(claimKey) {
      return liffapi.claims.get(claimKey).then((response) => {
        this.claimsByKey[claimKey] = response.entity
        return response.entity
      })
    },

    async fetchShipments(form) {
      return liffapi.org
        .fetchShipments(form.organization, form)
        .then((response) => {
          this.shipments = response.entities
          return response
        })
    },

    async fetchClaims(workCategory) {
      workCategory = workCategory || 'open'
      let orgKey = this.org.key

      return liffapi.fetchClaims(orgKey, workCategory).then((result) => {
        result.entities.forEach((c) => {
          this.summaryClaimsByKey[c.key] = c
        })
        return this.summaryClaims
      })
    },

    getClaim(claimKey) {
      if (claimKey in this.claimsByKey) {
        return this.claimsByKey[claimKey]
      }
      return null
    },

    loadCategories(orgKey) {
      this.notifications = this.notifications || []

      return liffapi
        .getCategories(orgKey)
        .then((result) => {
          this.categories = result
          let note = {
            message: `Categories ${Object.keys(result)?.length}`,
            body: result,
            type: 'notification',
            read: false,
          }
          this.notifications?.push(note)
        })
        .catch((error) => {
          throw new Error(`Load categories failed: ${error}`)
        })
    },

    clear() {
      const worker = useWorkerStore()
      this.claimsByKey = {}
      this.categories = {}
      this.inbox = []
      this.notifications = []
      this.org = {}
      this.parcelTemplates = {}
      this.recentReturns = []
      this.shipments = []
      this.summaryClaimsByKey = {}
      worker.clearOrgState()
    },

    async loadInbox(form) {
      // form = { orgKey, limit, offset, reload }
      if (form?.reload) {
        this.inbox = []
        return liffapi.messages.fetchEmails(form).then((messages) => {
          this.inbox = messages
        })
      }
      return liffapi.messages.fetchEmails(form).then((messages) => {
        let inboxByKeys = this.inbox.reduce(
          (obj, msg) => ({ ...obj, [msg.key]: msg }),
          {}
        )
        inboxByKeys = messages.reduce(
          (obj, msg) => ({ ...obj, [msg.key]: msg }),
          inboxByKeys
        )
        this.inbox = Object.values(inboxByKeys)
      })
    },

    async loadOrg(orgKey) {
      this.clear()

      return liffapi.org
        .get(orgKey)
        .then((result) => {
          this.org = result
          let note = {
            message: `Organization ${result?.slug}`,
            body: result,
            type: 'notification',
            read: false,
          }
          this.notifications?.push(note)
          this.loadCategories(orgKey)
          return result
        })
        .catch((error) => {
          throw new Error(`Load org failed: ${error}`)
        })
    },

    loadUser(orgKey) {
      const worker = useWorkerStore()
      return liffapi
        .getCurrentUser(orgKey, 'full')
        .then((result) => {
          this.org = result.org
          this.categories = result.categories
          worker.setWorker(result.user)
          return result.user
        })
        .catch((error) => {
          throw new Error(`Load org failed: ${error}`)
        })
    },

    async fetchParcelTemplates(force = false) {
      if (!force && Object.keys(this.parcelTemplates).length > 0) {
        return new Promise((resolve, reject) => resolve(this.parcelTemplates))
      }
      return liffapi.org.getParcelTemplates(this.key).then((templates) => {
        let templatesById = {}
        templates.forEach((t) => {
          templatesById[t.boxId] = t
        })
        this.parcelTemplates = templatesById
      })
    },

    messageUpdate(msg) {
      const i = this.inbox.findIndex((m) => m.key == msg.key)
      if (i >= 0) this.inbox.splice(i, 1, msg)
    },

    setOrg(org, categories) {
      this.org = org
      if (categories) this.categories = categories
    },

    updateClaim(claim) {
      this.claimsByKey[claim.key] = claim
      this.summaryClaimsByKey[claim.key] = asSummaryClaim(claim)
    },

    updateShipment(shipment) {
      let n = this.shipments.findIndex((s) => s.key == shipment.key)
      if (n >= 0) {
        this.shipments.splice(n, 1, shipment)
      }
    },

    async updateOrg(form) {
      return liffapi.org.patch(this.org.key, form).then((response) => {
        this.org = response.entity
        return response
      })
    },
  },
})

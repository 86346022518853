import { defineStore } from 'pinia'
import { useStorage } from '@vueuse/core'
import { useOrgStore } from '@/stores'

import liffapi from '@/liffapi'

export const useWorkerStore = defineStore('worker', {
  state: () => ({
    user: useStorage('worker:worker', {}),
    organizations: useStorage('worker:orgs', []),
    plateStatsByOrg: useStorage('worker:plateStatsByOrg', {}),
    plate: null,
    recentlyLoggedReports: useStorage('worker:recentlyLoggedReports', []),
    options: useStorage('worker:options', {}),
  }),

  getters: {
    hasRole(state) {
      return (role, orgKey) =>
        this.isAdmin ||
        state.user?.roles?.findIndex(
          (r) => r.role == role && r.organization == orgKey
        ) >= 0
    },

    isAdmin(state) {
      return state.user?.isAdmin
    },

    key(state) {
      return state.user?.key
    },

    prefs(state) {
      return state.user?.prefs || {}
    },
  },

  actions: {
    addLoggedItem(item) {
      this.recentlyLoggedReports.push(item)
      while (this.recentlyLoggedReports.length > 25) {
        this.recentlyLoggedReports.shift()
      }
    },

    clearOrgState() {
      this.recentlyLoggedReports = []
      this.plate = {}
    },

    fullPlateSet(statsByOrg) {
      this.plateStatsByOrg = Object.assign(
        this.plateStatsByOrg || {},
        statsByOrg
      )
      this.plateStatsByOrg._modifiedDate = new Date()
    },

    localOptionsGet(page) {
      const orgStore = useOrgStore()
      let orgKey = orgStore.key
      let orgOptions = this.options[orgKey] || {}
      let options = orgOptions[page] || {}

      return options
    },

    localOptionsSet(page, opts) {
      const orgStore = useOrgStore()
      let orgKey = orgStore.key
      let orgOptions = this.options[orgKey]
      if (!orgOptions) {
        orgOptions = {}
        this.options[orgKey] = orgOptions
      }
      let options = orgOptions[page] || {}

      this.options[orgKey][page] = opts
    },

    login(orgKey) {
      return liffapi.users
        .getCurrentWorker(orgKey)
        .then((user) => {
          this.user = user
          return user?.org
        })
        .catch(() => {
          window.location.pathname = '/login'
        })
    },

    logout() {
      sessionStorage.clear()
      localStorage.clear()
    },

    async orgs(force = false) {
      if (!force && this.organizations.length) return this.organizations

      return liffapi.getUserOrgs().then((data) => {
        this.organizations = data
        return data
      })
    },

    recentReports(orgKey) {
      if (orgKey) {
        return this.recentlyLoggedReports.filter((r) => r.orgKey == orgKey)
      }
      return this.recentlyLoggedReports
    },

    setPlate(plate) {
      this.plate = Object.assign({}, plate)
    },

    async setPref(prop, value) {
      return liffapi.users
        .updatePrefs(this.user.key, { [prop]: value })
        .then((response) => {
          Object.assign(this.user.prefs, response.entity)
          return response
        })
    },

    setWorker(user) {
      this.user = user
    },
  },
})

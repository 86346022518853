import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { useOruga } from '@oruga-ui/oruga-next'
import md5 from 'md5'

const DEBUG = import.meta.env.VITE_DEBUG == 'true'

export default {
  dateSort(a, b, isAsc) {
    let ret = isAsc
      ? new Date(b.dateCreated) - new Date(a.dateCreated)
      : new Date(a.dateCreated) - new Date(b.dateCreated)
    return ret
  },

  dedupe(list) {
    return [...new Set(list)]
  },

  /* Deep Duplication
   * pretty performant too.
   */
  duplicate(obj) {
    if (obj) {
      return JSON.parse(JSON.stringify(obj))
    }
    return null
  },

  getUserCountry() {
    let language =
      navigator?.languages?.length > 0
        ? navigator?.languages[0]
        : navigator?.language
    language = language || 'en-US'
    try {
      let parts = language?.split('-')
      return parts?.at(-1) || 'US'
    } catch (error) {
      console.error(
        `getUserCountry failed: ${navigator.language} | ${navigator.languages}`
      )
      return 'US'
    }
  },

  isClaimPickupURL(content) {
    const parsed = new URL(content)
    return this.isLiffHost(content) && parsed.hash.startsWith('#/claims/')
  },

  isDashboardUrl(content) {
    const parsed = new URL(content)
    return [
      'dashboard.liff.app',
      'dashboard.liff.dev',
      'dash.liff.ngrok.io',
    ].includes(parsed.host)
  },

  isDateYoungerThan(date, seconds) {
    if (typeof date === 'string') {
      date = new Date(date)
    }
    if ((!date) instanceof Date) return false

    let now = new Date()
    seconds = seconds || 600
    let millis = seconds * 1000

    const delta = now - date
    return delta < millis
  },

  isEmpty(obj) {
    return !Object.entries(obj).length
  },

  isEqual(...objects) {
    return objects.every(
      (obj) => JSON.stringify(obj) === JSON.stringify(objects[0])
    )
  },

  isEnvUrl(url) {
    const parsed = new URL(url)
    return (
      location.host.includes('localhost') || location.host.includes(parsed.host)
    )
  },

  isError(e) {
    return e && e.stack && e.message
  },

  isFile(variable) {
    if (typeof variable === 'undefined') {
      return false
    }
    return variable instanceof File
  },

  isImageUrl(url) {
    return /^https?:\/\/.+\.(jpg|jpeg|png|webp|avif|gif|svg|heic)$/.test(url)
  },

  isItemLabelUrl(url) {
    const parsed = new URL(url)
    let isItemLabel = parsed.pathname.startsWith('/i/')
    return Boolean(this.isLiffHost(url) && isItemLabel)
  },

  isLiffHost(url) {
    const parsed = new URL(url)
    console.debug(parsed)
    return (
      parsed.host == 'liff.app' ||
      parsed.host == 'liff.dev' ||
      parsed.host.includes('liff.ngrok.io')
    )
  },

  isLoginCode(content) {
    return (
      typeof content === 'string' &&
      content.length > 128 &&
      content.length < 512
    )
  },

  isMobile() {
    return window.innerWidth <= 768
  },

  isProtectShortUrl(url) {
    // location.host is: accounts.liff.[app|dev] event42.liff.ngrok.io/a/
    const parsed = new URL(url)
    let isProtectUrl =
      parsed.pathname.startsWith('/t/') || // tag
      parsed.pathname.startsWith('/r/') || // wallpaper
      parsed.pathname.startsWith('/s/') || // sticker
      parsed.pathname.startsWith('/p/') // tag-a-kid
    return Boolean(this.isLiffHost(url) && isProtectUrl)
  },

  isTagAKid(content) {
    const parsed = new URL(content)
    return parsed.pathname.startsWith('/p/');
  },

  isValidEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    // const re = /\S+@\S+/; // Future proof version
    const ret = re.test(String(email).toLowerCase())
    const valid = ret ? 'valid' : 'invalid'
    this.log(`${valid} email (${email})`)
    return ret
  },

  isValidIMEI(value) {
    value = value?.replace(/-|\s/g, '')
    if (/[^0-9]+/.test(value)) return false
    if (value.length < 15) return false
    if (value.length > 16) return false

    // The Luhn Algorithm
    let nCheck = 0
    let nDigit = 0
    let bEven = false
    value = value.replace(/\D/g, '')

    for (let n = value.length - 1; n >= 0; n--) {
      const cDigit = value.charAt(n)
      nDigit = parseInt(cDigit, 10)

      if (bEven) {
        if ((nDigit *= 2) > 9) nDigit -= 9
      }

      nCheck += nDigit
      bEven = !bEven
    }
    const ret = nCheck % 10 == 0
    const valid = ret ? 'valid' : 'invalid'
    this.log(`${valid} IMEI (${value})`)
    return ret
  },

  isValidName(name) {
    const re = /^(\w|\w-)+ (\w|\w-)+\s*/
    const ret = re.test(String(name))
    const valid = ret ? 'valid' : 'invalid'
    this.log(`${valid} name (${name})`)
    return ret
  },

  isValidPhoneNumber(value) {
    /*
     * Test:
     *  CA: +1-613-555-0169
     *  UK: +44 1632 960980
     *  AU: +61 1900 654 321
     *  NZ: +64 8 320 1232
     *  FR: +33-765-559-480
     *  local: 312-213-1234
     * */
    let phoneNumber = null
    let ret = null

    if (value === undefined || !value) return

    if (value.includes('+')) {
      phoneNumber = parsePhoneNumberFromString(value)
      ret = phoneNumber?.isPossible() === true
    } else {
      let country = this.getUserCountry()
      phoneNumber = parsePhoneNumberFromString(value, {
        defaultCountry: country,
      })
      if (phoneNumber === undefined) return
      ret = phoneNumber?.isValid() === true
    }
    const valid = ret ? 'valid' : 'invalid'
    this.log(`${valid} Phone Number (${phoneNumber?.getURI()})`)
    return ret
  },

  itemHash(itemKey) {
    if (itemKey) {
      return md5(itemKey).slice(-8)
    }
    return ''
  },

  log() {
    if (DEBUG) {
      console.log(...arguments)
    }
  },

  prettyDate(dt) {
    dt = new Date(dt)
    const d = dt.toLocaleDateString()
    return `${d}`
  },

  prettyDateTime(dt) {
    dt = new Date(dt)
    const d = dt.toLocaleDateString()
    const t = dt.toLocaleTimeString()
    return `${d} ${t}`
  },

  scrollToTop() {
    window.scrollTo(0, 0)
  },

  toast(message, variant) {
    const oruga = useOruga()

    oruga.notification.open({
      message: message,
      rootClass: 'toast-notification',
      duration: 5000,
      position: 'top',
      variant: variant || 'light',
    })
  },
}

import req from './axios'

export default {
  ai: {
    dlp(form) {
      console.log(`API: admin.ai.dlp(${form})`)
      return req.post(`/admin/ai/dlp`, form).then((response) => response.data)
    },

    gemini(form) {
      console.log(`API: admin.ai.gemini(${form})`)
      return req
        .post(`/admin/ai/gemini`, form)
        .then((response) => response.data)
    },
  },

  reunite: {
    addUser(form) {
      console.log(`API: admin.reunite.addUser(${form})`)
      return req
        .post(`/admin/reunite/users`, form)
        .then((response) => response.data)
    },

    listUsers() {
      console.log(`API: admin.reunite.listUsers()`)
      return req.get(`/admin/reunite/users`).then((response) => response.data)
    },

    extendUser(key, days) {
      let form = { days: days }
      console.log(`API: admin.reunite.extendUser(${key}, ${form})`)
      return req
        .post(`/admin/reunite/${key}/extend`, form)
        .then((response) => response.data)
    },
  },

  tasks: {
    queues() {
      console.log(`API: admin.tasks.queues()`)
      return req.get(`/admin/queues`).then((response) => response.data)
    },

    tasks(qName) {
      console.log(`API: admin.tasks.tasks()`)
      return req.get(`/admin/queues/${qName}`).then((response) => response.data)
    },

    getJob(key) {
      console.log(`API: admin.tasks.getJob(${key})`)
      return req.get(`/admin/jobs/${key}`).then((response) => response.data)
    },
  },

  claimLoginToken(key) {
    console.log(`API: admin.claimLoginToken(${key})`);
    return req
      .get(`/admin/claim/token?key=${key}`)
      .then((response) => response.data);
  },

  createOrg(form) {
    console.log(`API: admin.createOrg(${form})`)
    return req
      .post(`/admin/organization`, form)
      .then((response) => response.data)
  },

  updateOrg(orgKey, form) {
    console.log(`API: admin.updateOrg(${orgKey}, ${form})`)
    return req
      .patch(`/admin/organization/${orgKey}`, form)
      .then((response) => response.data)
  },

  uploadImage(form) {
    console.log(`API: admin.uploadImage(${form})`)
    return req.post(`/admin/image`, form).then((response) => response.data)
  },

  decodeKey(key) {
    console.log(`API: admin.decodeKey(${key})`)
    return req
      .get(`/admin/tools/decode?key=${key}`)
      .then((response) => response.data)
  },

  consumerLoginToken(key) {
    console.log(`API: admin.consumerLoginToken(${key})`)
    return req
      .get(`/admin/consumers/token?key=${key}`)
      .then((response) => response.data)
  },

  protectSyncSIB(key) {
    console.log(`API: admin.protectSyncSIB(${key})`)
    return req
      .post(`/admin/protect/${key}/sib`)
      .then((response) => response.data)
  },

  fetchConsumers() {
    console.log(`API: admin.fetchConsumers`)
    return req.get(`/admin/consumers`).then((response) => response.data)
  },

  fetchWorkers() {
    console.log(`API: admin.fetchWorkers`)
    return req.get(`/admin/workers`).then((response) => response.data)
  },

  fetchShipments() {
    console.log(`API: admin.fetchShipments`)
    return req.get(`/admin/shipments`).then((response) => response.data)
  },

  createShipment(shipmentKey, form) {
    console.log(`API: admin.createShipment`)
    // get rates
    return req.put(`/admin/shipments/${shipmentKey}/rates`, form)
  },

  purchaseLabel(shipmentKey, form) {
    console.log(`API: admin.purchaseLabel`)
    return req.put(`/admin/shipments/${shipmentKey}/purchase`, form)
  },

  createWorker(form) {
    console.log(`API: admin.createWorker(${form})`)
    return req.post(`/admin/workers`, form).then((response) => response.data)
  },

  updateWorker(form, orgKey) {
    console.log('API: admin.updateWorker(', form, ')')
    let params = orgKey ? { params: { org: orgKey } } : {}
    return req
      .patch(`/admin/workers`, form, params)
      .then((response) => response.data)
  },

  deauthWorker(userKey) {
    console.log(`API: users.deauthWorker(`, userKey, `)`)
    return req
      .post(`/admin/workers/${userKey}/deauth`)
      .then((response) => response.data)
  },

  unregisterProtect(key) {
    // TODO: require a message?
    console.log('API: admin.unregisterProtect(', key, ')')
    return req
      .patch(`/admin/protect/${key}/reset`)
      .then((response) => response.data)
  },

  valetedArchive(orgKey) {
    console.log(`API: admin.valatedArchive(${orgKey})`)
    return req
      .post(`/admin/valeted/archive?org=${orgKey}`)
      .then((response) => response.data)
  },

  orgClearConsumers(orgKey) {
    console.log(`API: admin.orgClearConsumers(${orgKey})`)
    return req
      .delete(`/admin/organization/${orgKey}/consumers`)
      .then((response) => response.data)
  },

  orgClearTaK(orgKey) {
    console.log(`API: admin.orgClearTaK(${orgKey})`)
    return req
      .delete(`/admin/organization/${orgKey}/tak`)
      .then((response) => response.data)
  },
}

import req from './axios'

export default {
  claimStats(orgKey) {
    console.log(`org.claimStats(${orgKey})`)
    return req
      .get(`/org/${orgKey}/claims/stats`)
      .then((response) => response.data)
  },

  deactivate(orgKey, form) {
    console.log(`API: org.deactivate(${orgKey}, `, form, ')')
    return req
      .post(`/org/${orgKey}/deactivate`, form)
      .then((response) => response.data)
  },

  fetchShipments(orgKey, form) {
    return req
      .get(`/org/${orgKey}/shipments`, { params: form })
      .then((response) => response.data)
  },

  get(orgKey) {
    console.log(`API: org.get(${orgKey})`)

    return req.get(`/org/${orgKey}`).then((response) => response.data)
  },

  getFeedback(orgKey, form) {
    console.log(`API: org.getFeedback(${orgKey}, `, form, ')')
    form = form || { limit: 100, offset: 0 }
    return req
      .get(`/org/${orgKey}/feedback`, { params: form })
      .then((response) => response.data)
  },

  getMatches(orgKey) {
    console.log(`org.getMatches(${orgKey})`)
    return req
      .get(`/org/${orgKey}/possible-matches`)
      .then((response) => response.data)
  },

  getParcelTemplates(orgKey) {
    console.log(`org.getParcelTemplates(${orgKey})`);
    return req.get(`/org/${orgKey}/parcels`).then((response) => response.data);
  },

  getPublic() {
    return req.get(`/org/`).then((response) => response.data)
  },

  itemTransfer(orgKey, form) {
    return req
      .post(`/org/${orgKey}/items/transfer`, form)
      .then((response) => response.data)
  },

  patch(orgKey, form) {
    console.log(`API: org.patch(${orgKey}, `, form, ')')
    return req.patch(`/org/${orgKey}`, form).then((response) => response.data)
  },
}
